import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import React from 'react';
import { OtherInquiriesForm } from 'sections/Contact/OtherInquiriesForm/OtherInquiriesForm';
import { B3 } from 'styles/Typography.styled';

export default () => {
    return (
        <CommonMargin>
            <Section title="Let's discuss!" isFirst withBreadCrumbs>
                <B3 mb={8} mt={4}>
                    Embark on a tech adventure! Complete the form, and our
                    experts will craft customized solutions
                    <br /> for your business growth.
                </B3>
                <OtherInquiriesForm />
            </Section>
        </CommonMargin>
    );
};
