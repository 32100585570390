import * as Common from 'components/_universal/Common';
import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const InsideFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InputsWrapper = styled.div<{ isError: boolean }>(
    ({ isError }) =>
        css`
            display: flex;
            gap: 28px;
            justify-content: space-between;
            margin-bottom: ${isError ? '20' : '48'}px;
            max-width: 610px;

            ${mediaQueries.xs} {
                margin-bottom: ${isError ? '20' : '38'}px;
                flex-direction: column;
            }
        `,
);

export const CheckboxInsideWrapper = styled.div(
    () =>
        css`
            align-items: center;
            display: flex;
            margin-right: 16px;
            white-space: nowrap;

            input {
                margin-right: 16px;
            }
        `,
);

export const StyledLink = styled(Link)(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.white};
            text-decoration: underline;
        `,
);

export const SubmitButton = styled(Common.Div)(
    ({ theme: { colors, spacing } }) =>
        css`
            background: ${colors.buttons};
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${spacing(1)} ${spacing(3)};
            max-width: 255px;
            height: 41px;
            margin-bottom: ${spacing(4)};

            p {
                font-size: 14px;
            }
        `,
);
