import { yupResolver } from '@hookform/resolvers/yup';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { Checkbox } from 'components/_inputs/Checkbox/Checkbox';
import InputPhone from 'components/_inputs/PhoneInput/InputPhone';
import { Textarea } from 'components/_inputs/Textarea/Textarea';
import { TextInput } from 'components/_inputs/TextInput/TextInput';
import { notify } from 'components/_modals/ToastNotification/ToastNotification';
import * as Common from 'components/_universal/Common';
import * as emailjs from 'emailjs-com';
import { navigate } from 'gatsby';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
    CheckboxInsideWrapper,
    StyledLink,
} from 'sections/Contact/ContactForm/ContactForm.styled';
import { ROUTE_PRIVACY_POLICY, ROUTE_THANK_YOU } from 'shared/paths';
import { otherInquiriesValidation } from 'shared/validation';
import { B2 } from 'styles/Typography.styled';
import { ToastTypes } from 'types/CommonTypes';
import { addFromMagazineField } from 'utils/cookies';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { useFormValidation } from 'utils/hooks/useFormValidation';
import {
    InputsWrapper,
    InsideFormWrapper,
    SubmitButton,
} from './OtherInquiriesForm.styled';

export const OtherInquiriesForm = () => {
    const isXs = useBreakpoint('xs');

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setError,
    } = useForm<FieldValues>({
        mode: 'all',
        resolver: yupResolver(otherInquiriesValidation),
    });
    const form = watch();

    const {
        country,
        ipAddress,
        validateSubmit,
        parsePhone,
        setInputDialCountry,
    } = useFormValidation(form);

    const onValidSubmit = () => {
        emailjs
            .send(
                'default_service',
                'codahead_main_page_form',
                {
                    name: form.name,
                    email: form.email,
                    description: form.description,
                    phone: parsePhone(),
                    country: country,
                    ip: ipAddress,
                    ...addFromMagazineField(),
                },
                'user_AFpd8OGklEw6C76b5ygNw',
            )
            .then(
                (result) => {
                    reset({
                        email: '',
                        name: '',
                        cb_privacyPolicy: undefined,
                        cb_nda: undefined,
                    });
                    navigate(ROUTE_THANK_YOU);
                },
                (error) => {
                    console.log('Error:: ', error);
                    return notify(error.text)[ToastTypes.ERROR]();
                },
            );
    };

    return (
        <div>
            <Common.Div flex="column">
                <Common.Div flex="row" gap="28px" w="100%">
                    <InsideFormWrapper>
                        <InputsWrapper
                            isError={!!errors.name || !!errors.email}
                        >
                            <TextInput
                                label="Your name*"
                                control={control}
                                errors={errors.name}
                                name="name"
                                type={'text'}
                            />
                            <TextInput
                                label="Email*"
                                control={control}
                                errors={errors.email}
                                name="email"
                                type={'email'}
                            />
                        </InputsWrapper>
                        <Common.Div w={isXs ? '100%' : '290px'}>
                            <InputsWrapper isError={!!errors.phone}>
                                <InputPhone
                                    label="Phone number*"
                                    name="phone"
                                    control={control}
                                    errors={errors.phone}
                                    setInputDialCountry={setInputDialCountry}
                                />
                            </InputsWrapper>
                        </Common.Div>
                        <InputsWrapper isError={!!errors.description}>
                            <Textarea
                                label="What would you like to do?*"
                                control={control}
                                errors={errors.description}
                                name="description"
                            />
                        </InputsWrapper>
                        <CheckboxInsideWrapper>
                            <Checkbox
                                control={control}
                                isError={!!errors.cb_privacyPolicy}
                                name="cb_privacyPolicy"
                            />
                            <B2>
                                I agree with{' '}
                                <StyledLink
                                    href={ROUTE_PRIVACY_POLICY}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </StyledLink>
                                *
                            </B2>
                        </CheckboxInsideWrapper>
                        <SubmitButton
                            mt={12}
                            onClick={handleSubmit(
                                () => validateSubmit(onValidSubmit, setError),
                                (e) => console.log(e),
                            )}
                        >
                            <B2>send</B2>
                            <Common.Svg src={ArrowSvg} />
                        </SubmitButton>
                    </InsideFormWrapper>
                </Common.Div>
            </Common.Div>
        </div>
    );
};
